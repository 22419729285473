import React from 'react';
import withPermissions from '../../common/hoc/with-permissions';

const forPostWriter = (ComponentWriter, ComponentUser) => {
  return withPermissions(({ can, ...props }) => {
    const Component = can('create', 'post') ? ComponentWriter : ComponentUser;
    return <Component {...props} />;
  });
};

export default forPostWriter;
