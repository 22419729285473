import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withFontClassName from '../../../common/hoc/with-font-class-name';
import styles from './category-header.scss';

const CategoryHeader = ({
  category,
  titleFontClassName,
  contentFontClassName,
}) => (
  <div
    className={classNames(
      styles.container,
      titleFontClassName,
      'blog-card-background-color',
    )}
  >
    <h1 className={classNames(styles.title, 'blog-text-color')}>
      {category.menuLabel}
    </h1>
    <div className={classNames(styles.description, contentFontClassName)}>
      <span className={classNames(styles.descriptionText, 'blog-text-color')}>
        {category.description}
      </span>
    </div>
  </div>
);

CategoryHeader.propTypes = {
  category: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

export default withFontClassName(CategoryHeader);
