import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Image } from '../../../common/components/image/image';
import Link from '../../../common/components/link/internal-link';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import styles from './banner-mobile.scss';

class BannerMobile extends React.Component {
  renderTitle = () => {
    const { title, link, titleFontClassName } = this.props;

    return (
      <div className={classNames(styles.titleContainer, titleFontClassName)}>
        <Link to={link.to}>
          <h1 className={classNames(styles.title, 'blog-text-color')}>
            {title}
          </h1>
        </Link>
      </div>
    );
  };

  renderImage = () => {
    const { link, image } = this.props;

    return (
      <div className={styles.imageContainer}>
        <Link className={styles.image} to={link.to}>
          <Image className={styles.imageFit} image={image} />
        </Link>
      </div>
    );
  };

  render = () => (
    <div className="blog-card-background-color">
      {this.renderImage()}
      {this.renderTitle()}
    </div>
  );
}

BannerMobile.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
};

export default withFontClassName(BannerMobile);
