import React, { useEffect } from 'react';
import { withSlotsPlaceholders } from '@wix/widget-plugins-ooi';
import { useTranslation } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import BlogPushNotifications from '../../../common/components/blog-push-notifications';
import ComponentsProvider from '../../../common/components/components-provider';
import PermissionsProvider from '../../../common/components/permissions-provider';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { useSelector } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { useI18n } from '../../../common/hooks/use-i18n';
import {
  getIsCreatedWithResponsiveEditor,
  getPinterestEnabled,
} from '../../../common/selectors/app-settings-selectors';
import pinterest from '../../../common/services/pinterest';
import {
  getIsMobile,
  getLanguage,
  isEditor as getIsEditor,
} from '../../../common/store/basic-params/basic-params-selectors';
import PostListMasonry from '../../components/post-list/post-list-masonry';
import PostListMobile from '../../components/post-list/post-list-mobile';
import PostListSimple from '../../components/post-list/post-list-simple';
import PostListItem from '../../components/post-list-item';
import SideBySideMobile from '../../components/post-list-item/mobile/side-by-side';
import TextOnImageMobile from '../../components/post-list-item/mobile/text-on-image';
import Router from '../router';
import styles from './app-root.scss';

const AppRoot: React.FC = () => {
  useAutoHeight();
  useInitPinterest();
  useI18n();

  return (
    <ResponsiveListener dataHook="feed-page-root" className={styles.root}>
      <PermissionsProvider>
        <ComponentsProvider
          PostListProGallery={LazyPostListProGallery}
          PostListSimple={PostListSimple}
          PostListMasonry={PostListMasonry}
          PostListMobile={PostListMobile}
          PostListItem={PostListItem}
          Post={LazyPost}
          SideBySideMobile={SideBySideMobile}
          TextOnImageMobile={TextOnImageMobile}
        >
          <>
            <Router />
            <BlogPushNotifications />
          </>
        </ComponentsProvider>
      </PermissionsProvider>
    </ResponsiveListener>
  );
};

function useInitPinterest() {
  const { language, isMobile, isPinterestEnabled } = useSelector((state) => ({
    language: getLanguage(state),
    isMobile: getIsMobile(state),
    isPinterestEnabled: getPinterestEnabled(state),
  }));

  useEffect(() => {
    pinterest.init({ language, isMobile, isPinterestEnabled });

    return () => {
      pinterest.removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function useAutoHeight() {
  const shouldSetAutoHeight = useSelector(
    (state) => getIsEditor(state) && getIsCreatedWithResponsiveEditor(state),
  );

  useEffect(() => {
    if (shouldSetAutoHeight) {
      document.body.style.height = 'auto';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const LazyPost = loadable(
  () =>
    import(
      /* webpackChunkName: "full-post-layout" */ '../../../common/components/post'
    ),
);

const LazyPostListProGallery = loadable(
  () =>
    import(
      /* webpackChunkName: "post-list-pro-gallery" */ '../../../common/components/post-list-pro-gallery'
    ),
);

export default withSlotsPlaceholders(withReduxStore(AppRoot));
