import React from 'react';
import PropTypes from 'prop-types';
import { POST_EXCERPT_MAX_LENGTH } from '@wix/communities-blog-client-common';
import DotDotDot from '../dotdotdot';
import SimpleRenderer from '../simple-renderer';
import styles from './responsive-content-excerpt.scss';

const ResponsiveContentExcerpt = ({
  post: { content, excerpt },
  lineCount,
}) => {
  return (
    <div>
      <DotDotDot
        clamp="auto"
        useExactLineHeight={true}
        maxLineCount={lineCount}
        className={styles.container}
      >
        <SimpleRenderer
          contentState={content}
          excerpt={excerpt}
          maxLength={POST_EXCERPT_MAX_LENGTH}
        />
      </DotDotDot>
    </div>
  );
};

ResponsiveContentExcerpt.propTypes = {
  post: PropTypes.object.isRequired,
  lineCount: PropTypes.number,
};

export default ResponsiveContentExcerpt;
