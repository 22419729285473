import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { resolveId } from '@wix/communities-blog-client-common';

import PopoverRoot from '../../../common/components/popovers/popover-root';
import PostSearchListItem from '../post-search-list-item';
import styles from './search-results-list.scss';

const renderItem = (props) => {
  // eslint-disable-next-line react/prop-types
  const { post, query, onPostLikeClick } = props;
  const postId = resolveId(post);
  const componentProps = {
    post,
    onLikeClick: onPostLikeClick,
    query,
    postMetadataFontSize: 12,
    postDescriptionFontSize: 16,
    postTitleFontSize: 26,
  };

  return (
    <div
      className={classNames(
        styles.listItemMobile,
        'search-results-list__list-item',
      )}
      key={postId}
      id={postId}
    >
      <PostSearchListItem
        type="search"
        {...componentProps}
        postDetailsLineCount={3}
      />
      <PopoverRoot />
    </div>
  );
};

const SearchResultsMobileList = (props) => (
  <Fragment>
    {props.posts.map((post) => renderItem({ ...props, post }))}
  </Fragment>
);

SearchResultsMobileList.propTypes = {
  onPostLikeClick: PropTypes.func.isRequired,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
};

export default SearchResultsMobileList;
