import React from 'react';
import PropTypes from 'prop-types';

class RemainingPostLoader extends React.Component {
  componentDidMount() {
    this.props.loadRemainingPosts?.();
  }

  render() {
    return null;
  }
}

RemainingPostLoader.propTypes = {
  loadRemainingPosts: PropTypes.func,
};

export default RemainingPostLoader;
