import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isSeo } from '../../store/basic-params/basic-params-selectors';
import { Image } from '../image/image';
import { connect } from '../runtime-context';

import styles from './post-list-item-image.scss';

export const PostListItemImageDesktop = ({
  image,
  url,
  isPublic,
  height,
  width,
}) => {
  const socialAttrs = useMemo(
    () => (isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true }),
    [isPublic, url],
  );

  return (
    <div
      className={classNames(styles.container)}
      data-hook="post-list-item-image"
    >
      <Image
        width={width}
        height={height}
        socialAttrs={socialAttrs}
        className={styles.imageFit}
        image={image}
      />
    </div>
  );
};

PostListItemImageDesktop.propTypes = {
  image: PropTypes.object.isRequired,
  url: PropTypes.string,
  isPublic: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

const mapRuntimeToProps = (state) => ({
  isSeo: isSeo(state),
});

export default connect(mapRuntimeToProps)(PostListItemImageDesktop);
