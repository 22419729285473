import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import Header from '../../../common/components/header';
import Link from '../../../common/components/link/internal-link';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import HeaderBackButton from '../../components/header-back-button';
import SearchHeader from './search-header';
import styles from './search-mobile-header.scss';

const SearchMobileHeader = ({ contentFontClassName, ...otherProps }) => {
  const left = (
    <div className={styles.backButton}>
      <HeaderBackButton arrow text="" component={Link} to="/" />
    </div>
  );

  const inputClassName = classNames(
    'blog-button-primary-text-color',
    contentFontClassName,
  );

  const borderClassName = 'blog-button-primary-text-background-color';

  const iconClassName = 'blog-button-primary-icon-fill';

  return (
    <SearchHeader
      component={Header}
      left={left}
      inputClassName={inputClassName}
      borderClassName={borderClassName}
      iconClassName={iconClassName}
      showWidget={false}
      {...otherProps}
    />
  );
};

SearchMobileHeader.propTypes = {
  contentFontClassName: PropTypes.string,
};

export default flowRight(withFontClassName)(SearchMobileHeader);
